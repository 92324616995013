import React from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

const LoginPage = ({ data, location }) => {
  return (
      
      <Helmet>
        <script src={withPrefix('login.js')} type="text/javascript" />
      </Helmet>

  )
}

export default LoginPage

//import * as React from "react"
//import { Link } from "gatsby"
//import PrivateRoute from "../components/privateRoute"
//
//const LoginPage = ({ data, location }) => {
//  return (
//    
//      <PrivateRoute location={location}></PrivateRoute>
//  )
//}
//
//export default LoginPage
//